import * as React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import Layout from "../prismic/layout"
import { Container } from "../components/Grid/Grid"

const PreviewPage = () => {
  return (
    <Layout>
      <Container>
        <h3>Loading Preview...</h3>
      </Container>
    </Layout>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
